import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API = 'https://power-bonus.onrender.com'
// const API = 'http://localhost:5000'


const BonusModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [appearanceId, setAppearanceId] = useState(null);

  useEffect(() => {
    const checkGameButtonStatus = async () => {
      if (!window.location.href.toLowerCase().includes('power')) {
        return;
      }
      try {
        const response = await axios.get(`${API}/api/game-button-status`);
        if (response.data.success && response.data.showButton) {
          setAdminId(response.data.adminId);
          setAppearanceId(response.data.appearanceId);
          setIsOpen(true);
        }
      } catch (error) {
        console.error('Error checking game button status:', error);
      }
    };

    const timer = setInterval(checkGameButtonStatus, 60000); // Check every minute

    checkGameButtonStatus(); // Initial check

    return () => clearInterval(timer);
  }, []);

  const handleClaimBonus = async () => {
    setIsOpen(false);
    const token = localStorage.getItem('token');
    const selectedNumbers = JSON.parse(localStorage.getItem('selectedNumbers') || '[]');
    const queryParams = new URLSearchParams({
      token: token,
      selectedNumbers: JSON.stringify(selectedNumbers)
    }).toString();

    try {
      await axios.post(`${API}/api/game-button-click`, { adminId, appearanceId });
    } catch (error) {
      console.error('Error recording button click:', error);
    }

    window.open(`https://www.powerbonus.xyz/?${queryParams}`, '_blank');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gradient-to-r from-yellow-600 to-red-800 p-6 rounded-lg shadow-2xl max-w-md w-full mx-4">
        <h2 className="text-3xl font-bold text-white mb-4">ልዩ የጉርሻ ስጦታ! Power Games!</h2>
        <p className="text-lg text-yellow-200 mb-6">አሁን በቤቱ ውስጥ ላሉት ተጫዋቾች ብቻ!</p>
        <button
          onClick={handleClaimBonus}
          className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-6 rounded-full transform transition duration-200 hover:scale-105 w-full"
        >
          አሁን ይጫወቱ! እስከ 2000 ብር አሸንፉ!
        </button>
        {/* <button
          onClick={() => setIsOpen(false)}
          className="mt-4 text-white hover:text-yellow-200 font-semibold"
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

export default BonusModal;