import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import CryptoJS from 'crypto-js';

function AdminStats() {
  const getApiUrl = () => {
    const hashedApiVersion = localStorage.getItem('apiVersion');
    if (hashedApiVersion) {
      const version2Hash = CryptoJS.SHA256('2').toString();
      if (hashedApiVersion === version2Hash) {
        return 'https://phoenix-bingo-2.onrender.com/api';
      }
    }
    return process.env.REACT_APP_API;
  };

  const API = getApiUrl();
  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` }  
      });
      const data = await response.json();
      setStats(data); 
    };

    fetchStats();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`; 
  }

  // Combine profits for entries with the same date and round to nearest integer
  const combinedStats = {};
  stats.statsByPeriod.forEach(period => {
    const dateStr = formatDate(period.date);
    if (!combinedStats[dateStr]) {
      combinedStats[dateStr] = 0;
    }
    combinedStats[dateStr] += period.profit;
  });

  // Round profit to nearest integer
  Object.entries(combinedStats).forEach(([date, profit]) => {
    combinedStats[date] = Math.round(profit);
  });

  return (
    <div className='m-4 p-4'>
      <Table bordered hover variant="dark">
        <thead>
          <tr>
            <th style={{backgroundColor: 'black'}}>Date</th>
            <th style={{backgroundColor: 'black'}}>Profit</th>
          </tr>  
        </thead>
        <tbody>
          {Object.entries(combinedStats).map(([date, profit]) => (
            <tr key={date}>
              <td>{date}</td> 
              <td>{profit} Birr</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default AdminStats;
