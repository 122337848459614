import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import PowerBonusManager from './PowerBonusManager';
import ZolaLoading from './ZolaLoading'; 
import { ChevronDown, ChevronUp, Edit2, Eye, Menu, X } from 'lucide-react';

const Zola = () => {
  const API_V1 = process.env.REACT_APP_API;
  const API_V2 = 'https://phoenix-bingo-2.onrender.com/api';
  const [admins, setAdmins] = useState([]);
  const [spinAdmins, setSpinAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [showTransactions, setShowTransactions] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const API = process.env.REACT_APP_API;
  const SPIN_API = process.env.REACT_APP_SPIN_API;
  const [passcode, setPasscode] = useState('');
  const [isPasscodeCorrect, setIsPasscodeCorrect] = useState(false);
  const [superZolaWallet, setSuperZolaWallet] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  const [activeView, setActiveView] = useState('bingo');
  const [selectedAdminGames, setSelectedAdminGames] = useState([]);
  const [showGamesModal, setShowGamesModal] = useState(false);
  const [selectedAdminUsername, setSelectedAdminUsername] = useState('');
  const [prizeWheelAdmins, setPrizeWheelAdmins] = useState([]);
  const [prizePool, setPrizePool] = useState([]);
  const [showPrizePoolModal, setShowPrizePoolModal] = useState(false);
  const [editedPrizePool, setEditedPrizePool] = useState([]);
  const PRIZE_WHEEL_API = process.env.REACT_APP_BONUS_API;
  const [editingAppearances, setEditingAppearances] = useState(null);
  const [editingPrize, setEditingPrize] = useState(null);
  const [adminStats, setAdminStats] = useState([]);
  const [showMonthlyProfits, setShowMonthlyProfits] = useState(false);
  const [showWeeklyProfits, setShowWeeklyProfits] = useState(false);
  const [selectedAdminProfits, setSelectedAdminProfits] = useState({ monthly: [], weekly: [] });
  const [showLastGames, setShowLastGames] = useState(false);
  const [lastGames, setLastGames] = useState([]);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [selectedStats, setSelectedStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDailyStatsModal, setShowDailyStatsModal] = useState(false);
  const [selectedAdminStats, setSelectedAdminStats] = useState([]);
  const [selectedAdminStatsUsername, setSelectedAdminStatsUsername] = useState('');
  const [showAllDailyProfits, setShowAllDailyProfits] = useState(false);
  const [showAllWeeklyProfits, setShowAllWeeklyProfits] = useState(false);
  const [showAllMonthlyProfits, setShowAllMonthlyProfits] = useState(false);
  const [allDailyProfits, setAllDailyProfits] = useState([]);
  const [allWeeklyProfits, setAllWeeklyProfits] = useState([]);
  const [allMonthlyProfits, setAllMonthlyProfits] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const handleShowDailyStats = (admin) => {
    setSelectedAdminStats(admin.statsByPeriod);
    setSelectedAdminStatsUsername(admin.username);
    setShowDailyStatsModal(true);
  };
  
  const handleCloseStatsModal = () => setShowStatsModal(false);

  useEffect(() => {
    if (isPasscodeCorrect) {
      fetchAdmins();
      fetchSpinAdmins();
      fetchPrizeWheelAdmins();
      fetchPrizePool();
    }
  }, [refreshData, isPasscodeCorrect]);

  useEffect(() => {
    fetchSuperZolaWallet();
  }, [admins]);

  const handleRefreshClick = () => {
    setIsLoading(true);
    setRefreshData(!refreshData);
    setIsLoading(false);
  };

  const fetchLastGames = async (adminId, username, api) => {
    setIsLoading(true);
    try {
      let response;
      try {
        response = await axios.get(`${API_V1}/admin/${adminId}/last-games`);
      } catch (error) {
        const alternateApi = api === API_V1 ? API_V2 : API_V1;
        response = await axios.get(`${alternateApi}/admin/${adminId}/last-games`);
      }
      setLastGames(response.data);
      setSelectedAdminUsername(username);
      setShowLastGames(true);
    } catch (error) {
      console.error('Error fetching last games:', error);
      alert('Failed to fetch last games');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecentTransactions = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API}/admin/transactions`);
      const filteredTransactions = response.data
        .filter(transaction => 
          transaction.toAdmin?.username?.startsWith('power-') || 
          transaction.toAdmin?.username?.toLowerCase().startsWith('zola')
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      // Filter out consecutive duplicates within 30 seconds
      const finalTransactions = filteredTransactions.reduce((acc, transaction, index, array) => {
        if (index === 0) {
          acc.push(transaction);
        } else {
          const prevTransaction = array[index - 1];
          const timeDiff = Math.abs(new Date(transaction.date) - new Date(prevTransaction.date)) / 1000; // difference in seconds
          
          if (
            transaction.toAdmin.username !== prevTransaction.toAdmin.username ||
            transaction.amount !== prevTransaction.amount ||
            timeDiff > 30
          ) {
            acc.push(transaction);
          }
        }
        return acc;
      }, []);
  
      setRecentTransactions(finalTransactions);
    } catch (error) {
      console.error('Error fetching recent transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowTransactions = () => {
    setIsLoading(true);
    fetchRecentTransactions();
    setShowTransactions(true);
    setIsLoading(false);
  };

  const fetchPrizePool = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${PRIZE_WHEEL_API}/prize-pool`);
      setPrizePool(response.data);
    } catch (error) {
      console.error('Error fetching prize pool:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPrizePool = () => {
    setEditedPrizePool([...prizePool]);
    setShowPrizePoolModal(true);
  };

  

  const handlePrizePoolChange = (index, field, value) => {
    const updatedPool = [...editedPrizePool];
    updatedPool[index][field] = Number(value);
    setEditedPrizePool(updatedPool);
  };

  const handlePrizePoolSubmit = async () => {
    setIsLoading(true);
    try {
      await axios.put(`${PRIZE_WHEEL_API}/prize-pool`, editedPrizePool);
      setPrizePool(editedPrizePool);
      setShowPrizePoolModal(false);
      alert('Prize pool updated successfully');
    } catch (error) {
      console.error('Error updating prize pool:', error);
      alert('Failed to update prize pool');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrizeWheelAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${PRIZE_WHEEL_API}/admin/all`);
      setPrizeWheelAdmins(response.data);
    } catch (error) {
      console.error('Error fetching prize wheel admins:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrizeWheelAdminGames = async (adminId) => {
    try {
      const response = await axios.get(`${PRIZE_WHEEL_API}/admin/${adminId}/games`);
      setSelectedAdminGames(response.data);
      setShowGamesModal(true);
    } catch (error) {
      console.error('Error fetching prize wheel admin games:', error);
    }
  };

  const addToPrizeWheelWallet = async (adminId) => {
    try {
      if (superZolaWallet < parseFloat(addAmount)) {
        alert('Super Zola does not have enough funds to transfer');
        return;
      }

      await axios.post(`${PRIZE_WHEEL_API}/admin/${adminId}/add-to-wallet`, { amount: parseFloat(addAmount) });

      const superZola = admins.find((admin) => admin.username === 'power-super');
      const updatedSuperZolaWallet = superZola.wallet - parseFloat(addAmount);
      await axios.put(`${API}/admin/${superZola._id}/wallet`, { wallet: updatedSuperZolaWallet });

      await fetchAdmins();
      await fetchPrizeWheelAdmins();
      await fetchSuperZolaWallet();
      
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating prize wheel admin wallet:', error);
    }
  };

  const handleEditMaxAppearances = async (adminId, maxAppearances) => {
    try {
      await axios.put(`${PRIZE_WHEEL_API}/admin/${adminId}/max-daily-appearances`, { maxAppearances });
      fetchPrizeWheelAdmins();
    } catch (error) {
      console.error('Error updating max appearances:', error);
    }
  };

  const handleEditAppearances = async (adminId, appearances) => {
    try {
      await axios.post(`${PRIZE_WHEEL_API}/admin/${adminId}/button-appearances`, { appearances });
      fetchPrizeWheelAdmins();
      setEditingAppearances(null);
    } catch (error) {
      console.error('Error updating appearances:', error);
    }
  };

  const handleEditPrize = async (adminId, fixedPrize) => {
    try {
      await axios.put(`${PRIZE_WHEEL_API}/admin/${adminId}/fixed-prize`, { fixedPrize });
      fetchPrizeWheelAdmins();
      setEditingPrize(null);
    } catch (error) {
      console.error('Error updating fixed prize:', error);
    }
  };

  const fetchAdminGames = async (adminId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${SPIN_API}/admin/${adminId}/games`);
      setSelectedAdminGames(response.data.slice(0, 50)); // Get only the last 50 games
      setShowGamesModal(true);
    } catch (error) {
      console.error('Error fetching admin games:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseGamesModal = () => setShowGamesModal(false);

  const handlePasscodeSubmit = (event) => {
    event.preventDefault();
    if (passcode === 'zola8563') {
      setIsPasscodeCorrect(true);
    } else {
      alert('Incorrect passcode. Please try again.');
    }
  };

  const handleUpdateSuperZolaWallet = (newWallet) => {
    setSuperZolaWallet(newWallet);
    // If you need to update this in your backend as well, you can add an API call here
  };
  
  const fetchSuperZolaWallet = () => {
    const superZola = admins.find((admin) => admin.username === 'power-super');
    if (superZola) {
      setSuperZolaWallet(superZola.wallet);
    } else {
      setSuperZolaWallet(0);
    }
  };

  const fetchAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API}/admin/naan/all/protected`);
      const filteredAdmins = response.data.filter((admin) =>
        admin.username.startsWith('power-')
      );
      setAdmins(filteredAdmins);
    } catch (error) {
      console.error('Error fetching admins:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpinAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${SPIN_API}/admin/all`);
      setSpinAdmins(response.data);
    } catch (error) {
      console.error('Error fetching spin admins:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditWallet = (adminId, isSpin = false) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const handleAddAmountChange = (event) => {
    setAddAmount(event.target.value);
  };

  const addToWallet = async (adminId, isSpin = false) => {
    setIsLoading(true);
    try {
      if (superZolaWallet < parseFloat(addAmount)) {
        alert('Super Zola does not have enough funds to transfer');
        return;
      }

      if (isSpin) {
        await axios.post(`${SPIN_API}/admin/${adminId}/add-to-wallet`, { amount: parseFloat(addAmount) });
      } else {
        const currentAdmin = admins.find((admin) => admin._id === adminId);
        const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
        await axios.put(`${API}/admin/${adminId}/wallet`, { wallet: updatedWallet, amount: addAmount });
      }

      const superZola = admins.find((admin) => admin.username === 'power-super');
      const updatedSuperZolaWallet = superZola.wallet - parseFloat(addAmount);
      await axios.put(`${API}/admin/${superZola._id}/wallet`, { wallet: updatedSuperZolaWallet });

      await fetchAdmins();
      await fetchSpinAdmins();
      await fetchSuperZolaWallet();
      
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().split('T')[0];
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };

    const getMaxProfit = (admin) => {
      if (!admin || !admin.statsByPeriod || admin.statsByPeriod.length === 0) {
        return { profit: 0, date: null };
    }
  
    const maxProfitStat = admin.statsByPeriod.reduce((max, stat) => 
      stat.profit > max.profit ? stat : max, 
      { profit: -Infinity, date: null }
    );
    return {
      profit: isFinite(maxProfitStat.profit) ? maxProfitStat.profit : 0,
      date: maxProfitStat.date
    };
  };

  const getTodaysTotalProfit = () => {
    return admins.reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const getMonthlyProfit = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthlyStats = admins.flatMap((admin) =>
      admin.statsByPeriod.filter((stat) => {
        const statDate = new Date(stat.date);
        return statDate.getMonth() === currentMonth && statDate.getFullYear() === currentYear;
      })
    );

    const totalProfit = monthlyStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit.toFixed(0);
  };

  const getTotalProfitToDate = () => {
    const totalProfit = admins.reduce((sum, admin) => sum + admin.totalProfit, 0);
    return totalProfit.toFixed(0);
  };

  const handleShowMonthlyProfits = (admin) => {
    setSelectedAdminProfits({ monthly: admin.last12MonthsProfits, weekly: admin.last12WeeksProfits });
    setSelectedAdminUsername(admin.username);
    setShowMonthlyProfits(true);
  };

  const handleShowWeeklyProfits = (admin) => {
    setSelectedAdminProfits({ monthly: admin.last12MonthsProfits, weekly: admin.last12WeeksProfits });
    setSelectedAdminUsername(admin.username);
    setShowWeeklyProfits(true);
  };

  const getMonthName = (monthIndex) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex];
  };

  const getWeekDates = (weekIndex) => {
    const today = new Date();
    const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - (7 * weekIndex));
    const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
    return `${startOfWeek.toLocaleDateString()} - ${endOfWeek.toLocaleDateString()}`;
  };

  const StatCard = ({ title, value }) => (
    <div className="bg-dark bg-opacity-50 rounded-lg shadow-lg p-3 transition-all duration-300 hover:bg-opacity-20">
      <h5 className=" font-medium text-white-300">{title}: {value} </h5>
    </div>
  );
  
  const ViewStatsButton = ({ admin }) => (
    <ActionButton 
      onClick={() => handleShowDailyStats(admin)} 
      icon={<Eye size={16} />}
    >
      View
    </ActionButton>
  );

  const MobileMenu = () => (
    <div className="md:hidden">
      <button
        onClick={() => setIsMobileMenuOpen(true)}
        className="fixed top-4 left-1 z-50 bg-dark bg-opacity-50 rounded-lg shadow-lg p-3 mr-5 rounded-full transition-all duration-300 hover:bg-opacity-20"
      >
        <Menu size={24} />
      </button>
      <div className='flex flex-row my-2'>
        <StatCard title="Today's Total Profit" value={getTodaysTotalProfit()} />
      </div>
      
      <div className={`fixed inset-0 bg-gray-800 bg-opacity-75 z-40 transition-opacity duration-300 ${isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className={`fixed left-0 top-0 bottom-0 w-64 bg-gray-900 text-white p-6 overflow-y-auto transition-transform duration-300 ease-in-out transform ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
          <div className="flex justify-end items-right mb-6">
            {/* <h2 className="text-xl font-bold">Menu</h2> */}
            <button onClick={() => setIsMobileMenuOpen(false)} className="text-white">
              X
            </button>
          </div>

          <div className="space-y-4 py-4">
            <button 
              onClick={() => {
                setActiveView('bingo');
                setIsMobileMenuOpen(false);
              }}
              className={`w-full px-1 py-1 rounded-md font-medium ${activeView === 'bingo' ? 'bg-gray-700 text-white' : 'bg-gray-700 text-white hover:bg-gray-600'}`}
            >
              Bingo
            </button>
            <button 
              onClick={() => {
                setActiveView('spin');
                setIsMobileMenuOpen(false);
              }}
              className={`w-full px-1 py-1 rounded-md font-medium ${activeView === 'spin' ? 'bg-gray-700 text-white' : 'bg-gray-700 text-white hover:bg-gray-600'}`}
            >
              Spin
            </button>
            <button 
              onClick={() => {
                setActiveView('prizewheel');
                setIsMobileMenuOpen(false);
              }}
              className={`w-full px-1 py-1 rounded-md font-medium ${activeView === 'prizewheel' ? 'bg-gray-700 text-white' : 'bg-gray-700 text-white hover:bg-gray-600'}`}
            >
              Bonus Wheel
            </button>
            <button 
              onClick={() => {
                handleShowTransactions();
                setIsMobileMenuOpen(false);
              }}
              className="w-full px-1 py-1 rounded-md font-medium bg-gray-700 hover:bg-blue-700 text-white"
            >
              Recent Transactions
            </button>
            <button 
              onClick={() => {
                handleRefreshClick();
                setIsMobileMenuOpen(false);
              }}
              className="w-full px-1 py-1 rounded-md font-medium bg-gray-700 hover:bg-green-700 text-white"
            >
              Refresh
            </button>
          </div>

          <div className="mt-6 space-y-4">
            <button 
              onClick={() => {
                aggregateDailyProfits();
                setShowAllDailyProfits(true);
                setIsMobileMenuOpen(false);
              }}
              className="w-full px-4 py-2 rounded-lg font-medium bg-green-600 hover:bg-purple-700 text-white"
            >
              Daily Profits
            </button>
            <button 
              onClick={() => {
                aggregateWeeklyProfits();
                setShowAllWeeklyProfits(true);
                setIsMobileMenuOpen(false);
              }}
              className="w-full px-4 py-2 rounded-lg font-medium bg-green-600 hover:bg-purple-700 text-white"
            >
              Weekly Profits
            </button>
            <button 
              onClick={() => {
                aggregateMonthlyProfits();
                setShowAllMonthlyProfits(true);
                setIsMobileMenuOpen(false);
              }}
              className="w-full px-4 py-2 rounded-lg font-medium bg-green-600 hover:bg-purple-700 text-white"
            >
              Monthly Profits
            </button>
          </div>

          <div className="mt-6 space-y-4">
            <StatCard title="Today's Total Profit" value={getTodaysTotalProfit()} />
            <StatCard className="" title="This Month's Profit" value={getMonthlyProfit()} />
            <StatCard className="" title="Total Profit to Date" value={getTotalProfitToDate()} />
          </div>
        </div>
      </div>
    </div>
  );
  
  
  const AdminActions = ({ admin, editingAdminId, addAmount, handleAddAmountChange, addToWallet, setEditingAdminId, handleEditWallet }) => (
    <>
      {admin.username !== 'power-super' && (
        editingAdminId === admin._id ? (
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={addAmount}
              onChange={handleAddAmountChange}
              className="w-20 px-2 py-1 bg-gray-700 text-white rounded"
              placeholder="Amount"
            />
            <ActionButton onClick={() => addToWallet(admin._id)} disabled={addAmount < 10000}>
              Add
            </ActionButton>
            <ActionButton onClick={() => setEditingAdminId(null)} variant="danger">
              Cancel
            </ActionButton>
          </div>
        ) : (
          <ActionButton onClick={() => handleEditWallet(admin._id)} icon={<Edit2 size={16} />}>
            Edit
          </ActionButton>
        )
      )}
    </>
  );
  
  const ActionButton = ({ onClick, disabled, variant = "primary", icon, children }) => {
    const baseClasses = "px-3 py-1 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-colors duration-150";
    const variantClasses = {
      primary: "bg-blue-600 hover:bg-blue-700 text-white",
      danger: "bg-red-600 hover:bg-red-700 text-white",
      warning: "bg-yellow-600 hover:bg-yellow-700 text-white",
    };
  
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${baseClasses} ${variantClasses[variant]} ${disabled ? 'opacity-50 cursor-not-allowed' : ''} flex items-center space-x-1`}
      >
        {icon && <span>{icon}</span>}
        <span>{children}</span>
      </button>
    );
  };

  const aggregateDailyProfits = () => {
    const dailyProfits = {};
    admins.forEach(admin => {
      admin.statsByPeriod.forEach(stat => {
        const date = new Date(stat.date).toISOString().split('T')[0];
        dailyProfits[date] = (dailyProfits[date] || 0) + stat.profit;
      });
    });
    
    const sortedDailyProfits = Object.entries(dailyProfits)
      .filter(([, profit]) => profit !== 0)
      .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
      .slice(0, 365);
    
    setAllDailyProfits(sortedDailyProfits);
  };

  const aggregateWeeklyProfits = () => {
    const weeklyProfits = [];
    let firstProfitDate = null;

    // Find the first date with a profit
    admins.forEach(admin => {
      admin.statsByPeriod.forEach(stat => {
        if (stat.profit > 0 && (!firstProfitDate || new Date(stat.date) < firstProfitDate)) {
          firstProfitDate = new Date(stat.date);
        }
      });
    });

    if (!firstProfitDate) return; // No profits found

    const profitsByWeek = {};
    admins.forEach(admin => {
      admin.statsByPeriod.forEach(stat => {
        const date = new Date(stat.date);
        const weekNumber = getWeekNumber(date, firstProfitDate);
        profitsByWeek[weekNumber] = (profitsByWeek[weekNumber] || 0) + stat.profit;
      });
    });

    // Convert to array, filter out weeks with no profit, and sort
    Object.entries(profitsByWeek).forEach(([week, profit]) => {
      if (profit > 0) {
        weeklyProfits.push({ week: parseInt(week), profit });
      }
    });

    weeklyProfits.sort((a, b) => b.week - a.week);
    
    setAllWeeklyProfits(weeklyProfits);
  };

  const getWeekNumber = (date, firstProfitDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstMondayOfFirstWeek = new Date(firstProfitDate.getTime() - ((firstProfitDate.getDay() + 6) % 7) * oneDay);
    const diffDays = Math.floor((date - firstMondayOfFirstWeek) / oneDay);
    return Math.floor(diffDays / 7) + 1;
  };

  const aggregateMonthlyProfits = () => {
    const monthlyProfits = {};
    admins.forEach(admin => {
      admin.statsByPeriod.forEach(stat => {
        const date = new Date(stat.date);
        const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
        monthlyProfits[monthKey] = (monthlyProfits[monthKey] || 0) + stat.profit;
      });
    });
    
    const sortedMonthlyProfits = Object.entries(monthlyProfits)
      .filter(([, profit]) => profit !== 0)
      .sort(([monthA], [monthB]) => monthB.localeCompare(monthA))
      .slice(0, 12);
    
    setAllMonthlyProfits(sortedMonthlyProfits);
  };

  if (!isPasscodeCorrect) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 to-indigo-800 flex items-center justify-center">
        <div className="bg-white bg-opacity-10 p-16 rounded-lg shadow-2xl backdrop-blur-md">
          <h2 className="text-4xl font-bold mb-6 text-white text-center">Zola Login</h2>
          <form onSubmit={handlePasscodeSubmit} className="space-y-4">
            <div>
              <label htmlFor="passcode" className="block text-lg font-medium text-gray-200">Passcode</label>
              <input
                type="password"
                id="passcode"
                value={passcode}
                onChange={(e) => setPasscode(e.target.value)}
                required
                color='black' 
                className="mt-1 block w-full px-3 py-2 bg-dark bg-opacity-20 border border-gray-300 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
            >
              Enter
            </button>
          </form>
        </div>
      </div>
    );
  }


  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 to-indigo-800 text-white p-4">
      {isLoading && <ZolaLoading />}
      <div className="mb-6 flex flex-col md:flex-row justify-between items-center">
        <h4 className="text-3xl font-bold mb-2 md:mb-0">Zola's Wallet: {superZolaWallet}</h4>
        <div className="hidden md:flex flex-wrap justify-center md:justify-end space-x-2 space-y-2 md:space-y-0">
          <button 
            onClick={() => setActiveView('bingo')} 
            className={`px-4 py-2 rounded-full font-medium ${activeView === 'bingo' ? 'bg-indigo-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
          >
            Bingo
          </button>
          <button 
            onClick={() => setActiveView('spin')} 
            className={`px-4 py-2 rounded-full font-medium ${activeView === 'spin' ? 'bg-indigo-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
          >
            Spin
          </button>
          <button 
            onClick={() => setActiveView('prizewheel')} 
            className={`px-4 py-2 rounded-full font-medium ${activeView === 'prizewheel' ? 'bg-indigo-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
          >
            Bonus Wheel
          </button>
          <button 
            onClick={handleShowTransactions} 
            className="px-4 py-2 rounded-full font-medium bg-blue-600 hover:bg-blue-700 text-white"
          >
            Recent Transactions
          </button>
          <button 
            onClick={handleRefreshClick} 
            className="px-4 py-2 rounded-full font-medium bg-green-600 hover:bg-green-700 text-white"
          >
            Refresh
          </button>
        </div>
      </div>

      <MobileMenu />

      {activeView === 'bingo' && (
        <div className="space-y-6">
          <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-3">
            <StatCard title="Today's Total Profit" value={getTodaysTotalProfit()} />
            <StatCard title="This Month's Profit" value={getMonthlyProfit()} />
            <StatCard title="Total Profit to Date" value={getTotalProfitToDate()} />
          </div>
          <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-3">
            <button 
              onClick={() => {
                aggregateDailyProfits();
                setShowAllDailyProfits(true);
              }} 
              className="bg-primary bg-opacity-20 rounded-lg shadow-lg p-2 transition-all duration-300 hover:bg-opacity-20"
            >
              Daily Profits
            </button>
            <button 
              onClick={() => {
                aggregateWeeklyProfits();
                setShowAllWeeklyProfits(true);
              }} 
              className="bg-primary bg-opacity-20 rounded-lg shadow-lg p-2 transition-all duration-300 hover:bg-opacity-20"
            >
              Weekly Profits
            </button>
            <button 
              onClick={() => {
                aggregateMonthlyProfits();
                setShowAllMonthlyProfits(true);
              }} 
              className="bg-primary bg-opacity-20 rounded-lg shadow-lg p-2 transition-all duration-300 hover:bg-opacity-20"
            >
              Monthly Profits
            </button>
          </div>

          <div className="bg-white bg-opacity-10 rounded-lg shadow-xl overflow-hidden">
            <div className="overflow-x-auto relative">
              <table className="w-full table-auto">
                <thead className="bg-gray-800 text-white sticky top-0 z-10">
                  <tr>
                  <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider sticky left-0 z-20 bg-gray-800">Username</th>
                    {["Total Profit", "Wallet", "Today's Profit", "Week Profit", "Month Profit", "Actions", "Last Games", "Daily Profits", "Weekly Profits", "Monthly Profits"].map((header) => (
                      <th key={header} className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {admins.filter(admin => admin.username !== 'power-super').map((admin) => (
                    <tr key={admin._id} className="bg-black bg-opacity-20 hover:bg-opacity-30 transition-colors duration-150">
                      <td className="px-2 py-3 sticky left-0 bg-black bg-opacity-20">{admin.username}</td>
                      <td className="px-2 py-3">{admin.totalProfit.toFixed(0)}</td>
                      <td className="px-2 py-3 text-warning">{admin.wallet}</td>
                      <td className="px-2 py-3 text-info">{getTodaysProfit(admin)}</td>
                      <td className="px-2 py-3">{admin.weekProfit?.toFixed(0)}</td>
                      <td className="px-2 py-3">{admin.monthProfit?.toFixed(0)}</td>
                      {/* <td className="px-4 py-3">{admin.avgMonthProfit?.toFixed(0)}</td>
                      <td className="px-4 py-3">{admin.avgAllTimeProfit?.toFixed(0)}</td> */}
                      {/* <td className="px-4 py-3">{getMaxProfit(admin)}</td> */}
                      <td className="px-2 py-3">
                        <AdminActions 
                          admin={admin}
                          editingAdminId={editingAdminId}
                          addAmount={addAmount}
                          handleAddAmountChange={handleAddAmountChange}
                          addToWallet={addToWallet}
                          setEditingAdminId={setEditingAdminId}
                          handleEditWallet={handleEditWallet}
                        />
                      </td>
                      <td className="px-2 py-3">
                        <ActionButton onClick={() => fetchLastGames(admin._id, admin.username, admin.api)} icon={<Eye size={16} />}>
                          View
                        </ActionButton>
                      </td>
                      <td className="px-2 py-3">
                        <ViewStatsButton admin={admin} />
                      </td>
                      <td className="px-2 py-3">
                        <ActionButton onClick={() => handleShowWeeklyProfits(admin)} icon={<Eye size={16} />}>
                          View
                        </ActionButton>
                      </td>
                      <td className="px-2 py-3">
                        <ActionButton onClick={() => handleShowMonthlyProfits(admin)} icon={<Eye size={16} />}>
                          View
                        </ActionButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {activeView === 'spin' && (
        <div className="bg-white bg-opacity-10 rounded-lg shadow-xl overflow-hidden mt-6">
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="bg-gray-800 text-white">
                <tr>
                  {["Username", "Wallet", "Today's Profit", "Total Profit", "Max Profit", "Stats by Day", "Games", "Actions"].map((header) => (
                    <th key={header} className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {spinAdmins.filter(admin => admin.username.toLowerCase().startsWith('zola')).map((admin) => (
                  <tr key={admin._id} className="bg-black bg-opacity-20 hover:bg-opacity-30 transition-colors duration-150">
                    <td className="px-4 py-3">{admin.username}</td>
                    <td className="px-4 py-3">{admin.wallet}</td>
                    <td className="px-4 py-3">{admin.todayProfit}</td>
                    <td className="px-4 py-3">{admin.totalProfit}</td>
                    <td className="px-4 py-3">{admin.maxProfit}</td>
                    <td className="px-4 py-3">
                      <ViewStatsButton stats={admin.statsByPeriod} />
                    </td>
                    <td className="px-4 py-3">
                      <ActionButton 
                        onClick={() => {
                          fetchAdminGames(admin._id);
                          setSelectedAdminUsername(admin.username);
                        }}
                        icon={<Eye size={16} />}
                      >
                        Games
                      </ActionButton>
                    </td>
                    <td className="px-4 py-3">
                      <AdminActions 
                        admin={admin}
                        editingAdminId={editingAdminId}
                        addAmount={addAmount}
                        handleAddAmountChange={handleAddAmountChange}
                        addToWallet={(id) => addToWallet(id, true)}
                        setEditingAdminId={setEditingAdminId}
                        handleEditWallet={(id) => handleEditWallet(id, true)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activeView === 'prizewheel' && (
        <PowerBonusManager 
          superZolaWallet={superZolaWallet}
          onUpdateSuperZolaWallet={handleUpdateSuperZolaWallet}
        />
      )}

      <Modal show={showDailyStatsModal} onHide={() => setShowDailyStatsModal(false)} centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">
            Daily Stats - {selectedAdminStatsUsername}
            <div className="text-sm mt-1">
              Max Profit: {getMaxProfit(admins.find(a => a.username === selectedAdminStatsUsername)).profit.toFixed(2)} Birr
              <br />
              Date of Max Profit: {new Date(getMaxProfit(admins.find(a => a.username === selectedAdminStatsUsername)).date).toLocaleDateString()}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-200 p-4">
          {selectedAdminStats.length > 0 ? (
            <ul className="list-group">
              {selectedAdminStats
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((stat, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                    <div className="d-flex flex-column">
                      <span className="font-bold text-gray-800">
                        {new Date(stat.date).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </span>
                    </div>
                    <span className="badge badge-primary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                      {stat.profit.toFixed(0)} Birr
                    </span>
                  </li>
                ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No stats available for this admin.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowDailyStatsModal(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showTransactions} onHide={() => setShowTransactions(false)} centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Recent Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-200 p-4">
          {recentTransactions.length > 0 ? (
            <ul className="list-group">
              {recentTransactions.map((transaction) => (
                <li key={transaction._id} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                  <div className="d-flex flex-column">
                  <span className="text-md font-bold text-gray-600">{transaction.toAdmin?.username.toUpperCase()}</span>
                    <span className="text-gray-800">
                      {new Date(transaction.date).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </span>
                    <span className="text-sm text-gray-600">
                      {new Date(transaction.date).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                      })}
                    </span>
                  </div>
                  <span className="text-md badge badge-secondary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                    {transaction.amount} Birr
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No recent transactions available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowTransactions(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>    

      <Modal show={showGamesModal} onHide={handleCloseGamesModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Last 50 Games for {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Numbers</th>
                <th>Winner</th>
                <th>Prize Amount</th>
              </tr>
            </thead>
            <tbody>
              {selectedAdminGames.map((game, index) => (
                <tr key={index}>
                  <td>{new Date(game.createdAt).toLocaleString()}</td>
                  <td>{game.numbers.join(', ')}</td>
                  <td>{game.winner}</td>
                  <td>{game.prizeAmount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseGamesModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPrizePoolModal} onHide={() => setShowPrizePoolModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Prize Pool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Prize</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {editedPrizePool.map((prize, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="number"
                      value={prize.prize}
                      onChange={(e) => handlePrizePoolChange(index, 'prize', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={prize.weight}
                      onChange={(e) => handlePrizePoolChange(index, 'weight', e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPrizePoolModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePrizePoolSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMonthlyProfits} onHide={() => setShowMonthlyProfits(false)} centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Monthly Profits - {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-200 p-4">
          {selectedAdminProfits?.monthly.some(profit => profit > 0) ? (
            <ul className="list-group">
              {selectedAdminProfits?.monthly
                .map((profit, index) => {
                  const date = new Date();
                  date.setMonth(date.getMonth() - (11 - index));
                  return { profit, date };
                })
                .filter(({ profit }) => profit > 0)
                .sort((a, b) => b.date - a.date)
                .map(({ profit, date }, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                    <div className="d-flex flex-column">
                      <span className="font-bold text-gray-800">
                        {date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                      </span>
                    </div>
                    <span className="badge badge-primary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                      {profit?.toFixed(0)} Birr
                    </span>
                  </li>
                ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No monthly profits available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowMonthlyProfits(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Weekly Profits Modal */}
      <Modal show={showWeeklyProfits} onHide={() => setShowWeeklyProfits(false)} centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Weekly Profits - {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-100 p-4">
          {selectedAdminProfits.weekly.some(profit => profit > 0) ? (
            <ul className="list-group">
              {selectedAdminProfits.weekly
                .map((profit, index) => ({ profit, week: 12 - index }))
                .filter(({ profit }) => profit > 0)
                .sort((a, b) => b.week - a.week)
                .map(({ profit, week }) => (
                  <li key={week} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                    <div className="d-flex flex-column">
                      <span className="font-bold text-gray-800">
                        Week {week}: {getWeekDates(week - 1)}
                      </span>
                    </div>
                    <span className="badge badge-primary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                      {profit?.toFixed(0)} Birr
                    </span>
                  </li>
                ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No weekly profits available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowWeeklyProfits(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLastGames} onHide={() => setShowLastGames(false)} size="lg" centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Last Games - {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-100 p-4">
          {lastGames.length > 0 ? (
            <ul className="list-group">
              {lastGames.map((game) => (
                <li key={game._id} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                  <div className="d-flex flex-column">
                    <span className="font-bold text-gray-800">
                      {new Date(game.createdAt).toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                      })}
                    </span>
                    <span className="text-sm text-gray-900">Players: {game.numberOfPlayers} | Status: {game.status}</span>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <span className="badge badge-primary badge-pill bg-blue-600 text-white px-3 py-2 shadow-sm mb-1">
                      Bet: {game.bettingAmount} Birr
                    </span>
                    <span className="badge badge-secondary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                      Profit: {(game.bettingAmount * game.numberOfPlayers * 0.2).toFixed(0)} Birr
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No games available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowLastGames(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAllDailyProfits} onHide={() => setShowAllDailyProfits(false)} size="lg" centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Daily Profits (Last 365 Days)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-200 p-4">
          {allDailyProfits.length > 0 ? (
            <ul className="list-group">
              {allDailyProfits.map(([date, profit], index) => (
                <li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                  <div className="d-flex flex-column">
                    <span className="font-bold text-gray-800">
                      {new Date(date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </span>
                  </div>
                  <span className="badge badge-primary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                    {profit.toFixed(0)} Birr
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No daily profits available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowAllDailyProfits(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAllWeeklyProfits} onHide={() => setShowAllWeeklyProfits(false)} size="lg" centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Weekly Profits (Starting from First Profit Week)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-200 p-4">
          {allWeeklyProfits.length > 0 ? (
            <ul className="list-group">
              {allWeeklyProfits.map(({ week, profit }, index) => (
                <li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                  <div className="d-flex flex-column">
                    <span className="font-bold text-gray-800">Week {week}</span>
                  </div>
                  <span className="badge badge-primary badge-pill bg-green-600 text-white px-3 py-2 shadow-sm">
                    {profit.toFixed(0)} Birr
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted text-center">No weekly profits available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowAllWeeklyProfits(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAllMonthlyProfits} onHide={() => setShowAllMonthlyProfits(false)} size="lg" centered>
        <Modal.Header closeButton className="bg-green-600 text-white">
          <Modal.Title className="text-lg font-bold">Monthly Profits (Last Year)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-200 p-4">
          {allMonthlyProfits.length > 0 ? (
            <ul className="list-group">
              {allMonthlyProfits.map(([month, profit], index) => {
                const date = new Date();
                date.setMonth(date.getMonth() - index);
                return (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-white my-1 shadow-sm rounded">
                    <div className="d-flex flex-column">
                      <span className="font-bold text-gray-800">
                        {date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                      </span>
                    </div>
                    <span className="badge badge-primary badge-pill bg-blue-600 text-white px-3 py-2 shadow-sm">
                      {profit.toFixed(0)} Birr
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-muted text-center">No monthly profits available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowAllMonthlyProfits(false)} className="px-4 py-2 rounded-lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Zola;
