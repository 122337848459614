import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Table } from 'react-bootstrap';


const Keriti = () => {
  const [version, setVersion] = useState(1);
  const API_V1 = process.env.REACT_APP_API;
  const API_V2 = 'https://phoenix-bingo-2.onrender.com/api';
  const [admins, setAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isPasscodeCorrect, setIsPasscodeCorrect] = useState(false);
  const [superKeritiWallet, setSuperKeritiWallet] = useState(0);
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('0935880205-');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authUsername, setAuthUsername] = useState('');
  const [registrationApi, setRegistrationApi] = useState(API_V1);
  const [showTransactions, setShowTransactions] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [showStats, setShowStats] = useState(false);
  const [selectedAdminStats, setSelectedAdminStats] = useState([]);
  const [showLastGames, setShowLastGames] = useState(false);
  const [lastTenGames, setLastTenGames] = useState([]);
  const [selectedAdminUsername, setSelectedAdminUsername] = useState('');

  const fetchRecentTransactions = async () => {
    try {
      const response1 = await axios.get(`${API_V1}/admin/transactions`);
      const response2 = await axios.get(`${API_V2}/admin/transactions`);
      const allTransactions = [...response1.data, ...response2.data];
      const filteredTransactions = allTransactions
        .filter(transaction => 
          transaction.toAdmin?.username?.startsWith('0935880205') || 
          transaction.toAdmin?.username?.startsWith('aba')
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setRecentTransactions(filteredTransactions);
    } catch (error) {
      console.error('Error fetching recent transactions:', error);
    }
  };

  const handleShowTransactions = () => {
    fetchRecentTransactions();
    setShowTransactions(true);
  };
  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    fetchSuperKeritiWallet();
    fetchAdmins();
  }, [admins]);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (value.startsWith('0935880205-')) {
      setUsername(value);
    }
  };

  const handleRefreshClick = () => {
    setIsLoading(true);
    fetchAdmins().finally(() => {
      setIsLoading(false);
    });
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${registrationApi}/admin/signup/protected/naan`, { username, password, initialWallet });
      alert('User registered successfully');
      setShowRegistration(false);
      setUsername('');
      setPassword('');
      setInitialWallet('');
      fetchSuperKeritiWallet();
      fetchAdmins();
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Failed to register user');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasscodeSubmit = (event) => {
    event.preventDefault();
    if (passcode === 'ker9890' && authUsername === 'keriti') {
      setIsPasscodeCorrect(true);
    } else {
      alert('Incorrect passcode or username. Please try again.');
    }
  };

  const [blockingAdminId, setBlockingAdminId] = useState(null);

  const handleBlockUnblock = async (adminId, action) => {
    try {
      setIsLoading(true);
      let response;
  
      try {
        // First, try API_V1
        response = await axios.post(`${API_V1}/admin/${adminId}/${action}`);
      } catch (error) {
        console.log('API_V1 failed, trying API_V2');
        // If API_V1 fails, try API_V2
        response = await axios.post(`${API_V2}/admin/${adminId}/${action}`);
      }
      fetchAdmins();
    } catch (error) {
      console.error(`Error ${action}ing admin:`, error);
      alert(`Failed to ${action} admin`);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchSuperKeritiWallet = () => {
    const superKeriti = admins.find((admin) => admin.username === '0935880205-super' && admin.api === API_V1);
    if (superKeriti) {
      setSuperKeritiWallet(superKeriti.wallet);
    } else {
      setSuperKeritiWallet(0);
    }
  };

  const naturalSort = (a, b) => {
    return a.username.localeCompare(b.username, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  };

  const fetchAdmins = async () => {
    try {
      const [response1, response2] = await Promise.all([
        axios.get(`${API_V1}/admin/naan/all/protected`),
        axios.get(`${API_V2}/admin/naan/all/protected`)
      ]);
  
      const allAdmins = [
        ...response1.data.map(admin => ({ ...admin, api: API_V1 })),
        ...response2.data.map(admin => ({ ...admin, api: API_V2 }))
      ];
      const filteredAdmins = allAdmins.filter((admin) =>
        (admin.username.startsWith('0935880205') && !admin.username.endsWith('-old')) || admin.username.startsWith('aba')
      );
      const sortedAdmins = filteredAdmins.sort(naturalSort);
      setAdmins(sortedAdmins);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleEditWallet = (adminId) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const addToWallet = async (adminId, api) => {
    try {
      if(superKeritiWallet < addAmount){
        alert('Insufficient funds');
      }
      
      const currentAdmin = admins.find((admin) => admin._id === adminId);
      const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
      await axios.put(`${api}/admin/${adminId}/wallet`, { wallet: updatedWallet, amount: addAmount });

      const superKeriti = admins.find((admin) => admin.username === '0935880205-super' && admin.api === API_V1);
      const updatedSuperKeritiWallet = superKeriti.wallet - parseFloat(addAmount);
      await axios.put(`${API_V1}/admin/${superKeriti._id}/wallet`, { wallet: updatedSuperKeritiWallet, amount: addAmount });

      await fetchAdmins();
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    }
  };

  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().slice(0, 10);
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };

  const getTodaysTotalProfit = () => {
    return admins.reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const fetchLastTenGames = async (adminId, adminUsername) => {
    setIsLoading(true);
    try {
      let gamesFromV1 = [];
      let gamesFromV2 = [];
  
      if (adminUsername !== '0935880205-16') {
        try {
          console.log('v1 hit')
          const responseV1 = await axios.get(`${API_V1}/admin/${adminId}/last-games`);
          gamesFromV1 = responseV1.data;
        } catch (error) {
          console.log('API_V1 failed:', error);
        }
      }
  
      try {
        console.log('v2 hit')
        const responseV2 = await axios.get(`${API_V2}/admin/${adminId}/last-games`);
        gamesFromV2 = responseV2.data;
      } catch (error) {
        console.log('API_V2 failed:', error);
      }
  
      // Concatenate the results
      const allGames = [...gamesFromV1, ...gamesFromV2];
  
      // Sort the games by date (assuming there's a date field)
      // and take the last 10
      const sortedGames = allGames.sort((a, b) => new Date(b.date) - new Date(a.date));
      const lastTenGames = sortedGames.slice(0, 10);
  
      setLastTenGames(lastTenGames);
      setSelectedAdminUsername(adminUsername);
      setShowLastGames(true);
    } catch (error) {
      console.error('Error fetching last 10 games:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowStats = (admin) => {
    setSelectedAdminStats(admin.statsByPeriod.reverse());
    setSelectedAdminUsername(admin.username);
    setShowStats(true);
  };

  if (!isPasscodeCorrect) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              Admin Dashboard
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handlePasscodeSubmit}>
            <div className="rounded-md shadow-sm -space-y-px ">
              <div>
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Username"
                  value={authUsername}
                  onChange={(e) => setAuthUsername(e.target.value)}
                />
              </div>
              <div className='mt-2'>
                <label htmlFor="passcode" className="sr-only">
                  Passcode
                </label>
                <input
                  id="passcode"
                  name="passcode"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Passcode"
                  value={passcode}
                  onChange={(e) => setPasscode(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="flex flex-column justify-between">
            <h1 className="font-bold text-gray-900">Keriti</h1>
            
            <div className="flex space-x-4">
              <button
                onClick={() => setShowRegistration(true)}
                className="px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Register User
              </button>
              <button
                onClick={handleShowTransactions}
                className="px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Recent Transactions
              </button>
              <button
                onClick={handleRefreshClick}
                className="px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Refresh
              </button>
            </div>
          </div>

          <div className="overflow-hidden sm:rounded-lg">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <button className="btn btn-dark font-medium m-2">Super Keriti's Wallet: {superKeritiWallet}</button>
              <button className="btn btn-dark font-medium m-2">Today's Total Profit: {getTodaysTotalProfit()}</button>
            </div>
          </div>

          <Modal show={showTransactions} onHide={() => setShowTransactions(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Recent Transactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>To Admin</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentTransactions.map((transaction) => (
                  <tr key={transaction._id}>
                    <td>{new Date(transaction.date).toLocaleString()}</td>
                    <td>{transaction.toAdmin?.username}</td>
                    <td>{transaction.amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-200">
                      <tr>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Username
                        </th>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Balance
                        </th>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Today
                        </th>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Wallet
                        </th>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Profit
                        </th>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Games
                        </th>
                        <th scope="col" className="px-1 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                          Block
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {admins.filter(admin => admin.username !== '0935880205-super').map((admin) => (
                        <tr key={`${admin._id}-${admin.api}`}>
                          <td className="px-3 py-4 whitespace-nowrap text-md font-medium text-gray-900">{admin.username}</td>
                          <td className="py-4 whitespace-nowrap text-md text-gray-500">{admin.wallet}</td>
                          <td className="py-4 whitespace-nowrap text-md text-gray-500">{getTodaysProfit(admin)}</td>
                          <td className="py-4 whitespace-nowrap text-md font-medium">
                            {admin.username !== '0935880205-super' && (
                              editingAdminId === admin._id ? (
                                <div className="flex items-center space-x-2">
                                  <input
                                    type="number"
                                    value={addAmount}
                                    onChange={(e) => setAddAmount(e.target.value)}
                                    className="block w-24 px-3 py-2 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Amount"
                                  />
                                  <button
                                    onClick={() => addToWallet(admin._id, admin.api)}
                                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    disabled={addAmount < 10000}
                                  >
                                    Add
                                  </button>
                                  <button
                                    onClick={() => setEditingAdminId(null)}
                                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              ) : (
                                <button
                                  onClick={() => handleEditWallet(admin._id)}
                                  className="btn btn-info"
                                >
                                  Add
                                </button>
                              )
                            )}
                          </td>
                          <td className="py-4 whitespace-nowrap text-md font-medium">
                            <button
                              onClick={() => handleShowStats(admin)}
                              className="btn btn-dark"
                            >
                              Stats
                            </button>
                          </td>
                          <td className="py-4 whitespace-nowrap text-md font-medium">
                            <button
                              onClick={() => fetchLastTenGames(admin._id, admin.username, admin.api)}
                              className="btn btn-warning"
                            >
                              Games
                            </button>
                          </td>
                          <td>
                            {admin.isBlocked ? (
                              <Button 
                                variant="success" 
                                size="sm" 
                                onClick={() => handleBlockUnblock(admin._id, 'unblock', admin.api)}
                                disabled={blockingAdminId === admin._id}
                              >
                                Unblock
                              </Button>
                            ) : (
                              <Button 
                                variant="danger" 
                                size="sm" 
                                onClick={() => handleBlockUnblock(admin._id, 'block', admin.api)}
                                disabled={blockingAdminId === admin._id}
                              >
                                Block
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showStats} onHide={() => setShowStats(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stats by Period - {selectedAdminUsername} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {selectedAdminStats.map((stat, index) => (
                <tr key={index}>
                  <td>{formatDate(stat.date)}</td>
                  <td>{Math.round(stat.profit)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal show={showLastGames} onHide={() => setShowLastGames(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Last Games - {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Betting Amount</th>
                <th>Number of Players</th>
                <th>Winning Amount</th>
                <th>Profit</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {lastTenGames.map((game) => (
                <tr key={game._id}>
                  <td>{formatDate(new Date(game.createdAt)).toLocaleString()}</td>
                  <td>{game.bettingAmount}</td>
                  <td>{game.numberOfPlayers}</td>
                  <td>{game.payoutToWinner}</td>
                  <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                  <td>{game.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal show={showRegistration} onHide={() => setShowRegistration(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleRegistration}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="api">
              <Form.Label>API Version</Form.Label>
              <Form.Control as="select" onChange={(e) => setRegistrationApi(e.target.value)} value={registrationApi}>
                <option value={API_V1}>Cartella 1</option>
                <option value={API_V2}>Cartella 2</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Register
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {isLoading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      )}
    </div>
  );
};

export default Keriti;