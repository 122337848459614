import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import ZolaLoading from './ZolaLoading'; 
import { Edit2, Eye, Plus, Trash2 } from 'lucide-react';

const PowerBonusManager = ({ superZolaWallet, onUpdateSuperZolaWallet }) => {
  const [prizeWheelAdmins, setPrizeWheelAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [editingPrize, setEditingPrize] = useState(null);
  const [selectedAdminGames, setSelectedAdminGames] = useState([]);
  const [showGamesModal, setShowGamesModal] = useState(false);
  const [selectedAdminUsername, setSelectedAdminUsername] = useState('');
  const [showAppearanceModal, setShowAppearanceModal] = useState(false);
  const [newAppearance, setNewAppearance] = useState({ date: '', time: '' });
  const [selectedAdminForAppearance, setSelectedAdminForAppearance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const PRIZE_WHEEL_API = process.env.REACT_APP_BONUS_API;

  useEffect(() => {
    fetchPrizeWheelAdmins();
  }, []);

  const fetchPrizeWheelAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${PRIZE_WHEEL_API}/admin/all`);
      const adminsWithAppearances = await Promise.all(
        response.data.map(async (admin) => {
          const appearancesResponse = await axios.get(`${PRIZE_WHEEL_API}/admin/${admin._id}/button-appearances`);
          return { ...admin, gameButtonAppearances: appearancesResponse.data.appearances };
        })
      );
      setPrizeWheelAdmins(adminsWithAppearances);
    } catch (error) {
      console.error('Error fetching prize wheel admins:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAppearance = async () => {
    if (!newAppearance.date || !newAppearance.time) return;
    setIsLoading(true);
    try {
      const admin = prizeWheelAdmins.find(a => a._id === selectedAdminForAppearance);
      const localDate = new Date(`${newAppearance.date}T${newAppearance.time}`);
      const isoString = localDate.toISOString();
      const newAppearances = [
        ...(admin.gameButtonAppearances || []),
        { startTime: isoString }
      ];
      await axios.post(`${PRIZE_WHEEL_API}/admin/${selectedAdminForAppearance}/button-appearances`, 
        { appearances: newAppearances }
      );
      fetchPrizeWheelAdmins();
      setNewAppearance({ date: '', time: '' });
      setShowAppearanceModal(false);
    } catch (error) {
      console.error('Error adding appearance:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveAppearance = async (adminId, index) => {
    setIsLoading(true);
    try {
      const admin = prizeWheelAdmins.find(a => a._id === adminId);
      const newAppearances = admin.gameButtonAppearances.filter((_, i) => i !== index);
      await axios.post(`${PRIZE_WHEEL_API}/admin/${adminId}/button-appearances`, { appearances: newAppearances });
      fetchPrizeWheelAdmins();
    } catch (error) {
      console.error('Error removing appearance:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditFixedPrize = async (adminId, fixedPrize) => {
    setIsLoading(true);
    try {
      await axios.put(`${PRIZE_WHEEL_API}/admin/${adminId}/fixed-prize`, { fixedPrize: parseInt(fixedPrize) });
      fetchPrizeWheelAdmins();
      setEditingPrize(null);
    } catch (error) {
      console.error('Error updating fixed prize:', error);
      alert('Failed to update fixed prize');
    } finally {
      setIsLoading(false);
    }
  };

  const addToPrizeWheelWallet = async (adminId) => {
    setIsLoading(true);
    try {
      if (superZolaWallet < parseFloat(addAmount)) {
        alert('Super Zola does not have enough funds to transfer');
        return;
      }
      await axios.post(`${PRIZE_WHEEL_API}/admin/${adminId}/add-to-wallet`, { amount: parseFloat(addAmount) });
      const updatedSuperZolaWallet = superZolaWallet - parseFloat(addAmount);
      onUpdateSuperZolaWallet(updatedSuperZolaWallet);
      await fetchPrizeWheelAdmins();
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating prize wheel admin wallet:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrizeWheelAdminGames = async (adminId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${PRIZE_WHEEL_API}/admin/${adminId}/games`);
      setSelectedAdminGames(response.data);
      setShowGamesModal(true);
    } catch (error) {
      console.error('Error fetching prize wheel admin games:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const AdminCard = ({ admin }) => (
    <div className="bg-white bg-opacity-10 rounded-lg shadow-xl p-4 mb-4">
      <h3 className="text-xl font-bold mb-2">{admin.username}</h3>
      <div className="grid grid-cols-2 gap-2 mb-4">
        <div>
          <p className="text-sm">Wallet: {admin.wallet}</p>
          <p className="text-sm">Today's Payout: {admin.todayPayout}</p>
        </div>
        <div>
          <p className="text-sm">Total Payout: {admin.totalPayout}</p>
          <p className="text-sm">Fixed Prize: {admin.fixedPrize}</p>
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        <Button 
          variant="primary" 
          size="sm" 
          onClick={() => {
            setSelectedAdminForAppearance(admin._id);
            setShowAppearanceModal(true);
          }}
        >
          Manage Appearances
        </Button>
        <Button 
          variant="info" 
          size="sm" 
          onClick={() => {
            fetchPrizeWheelAdminGames(admin._id);
            setSelectedAdminUsername(admin.username);
          }}
        >
          View Games
        </Button>
        <Button 
          variant="warning" 
          size="sm" 
          onClick={() => setEditingAdminId(admin._id)}
        >
          Add to Wallet
        </Button>
        <Button 
          variant="secondary" 
          size="sm" 
          onClick={() => setEditingPrize(admin._id)}
        >
          Edit Fixed Prize
        </Button>
      </div>
      {editingAdminId === admin._id && (
        <div className="mt-4">
          <input
            type="number"
            value={addAmount}
            onChange={(e) => setAddAmount(e.target.value)}
            className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
            placeholder="Amount to add"
          />
          <div className="flex gap-2">
            <Button 
              variant="success" 
              size="sm" 
              onClick={() => addToPrizeWheelWallet(admin._id)} 
              disabled={addAmount < 100}
            >
              Add to Wallet
            </Button>
            <Button 
              variant="danger" 
              size="sm" 
              onClick={() => setEditingAdminId(null)}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {editingPrize === admin._id && (
        <div className="mt-4">
          <input
            type="number"
            value={admin.fixedPrize}
            onChange={(e) => {
              const newAdmins = [...prizeWheelAdmins];
              const index = newAdmins.findIndex(a => a._id === admin._id);
              newAdmins[index].fixedPrize = e.target.value;
              setPrizeWheelAdmins(newAdmins);
            }}
            className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
          />
          <div className="flex gap-2">
            <Button 
              variant="success" 
              size="sm" 
              onClick={() => handleEditFixedPrize(admin._id, admin.fixedPrize)}
            >
              Save Fixed Prize
            </Button>
            <Button 
              variant="danger" 
              size="sm" 
              onClick={() => setEditingPrize(null)}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 to-indigo-800 text-white p-4">
      {isLoading && <ZolaLoading />}
      <h2 className="text-3xl font-bold mb-6">Power Bonus Manager</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {prizeWheelAdmins
          .filter(admin => admin.username.toLowerCase().startsWith('zola'))
          .map((admin) => (
            <AdminCard key={admin._id} admin={admin} />
          ))
        }
      </div>

      <Modal show={showGamesModal} onHide={() => setShowGamesModal(false)} size="lg">
        <Modal.Header closeButton className="bg-gray-800 text-white">
          <Modal.Title>Last 50 Games for {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-700 text-white">
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Date</th>
                <th>Numbers</th>
                <th>Winner</th>
                <th>Prize Amount</th>
              </tr>
            </thead>
            <tbody>
              {selectedAdminGames.map((game, index) => (
                <tr key={index}>
                  <td>{new Date(game.createdAt).toLocaleString()}</td>
                  <td>{game.numbers.join(', ')}</td>
                  <td>{game.winner}</td>
                  <td>{game.prizeAmount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="bg-gray-800">
          <Button variant="secondary" onClick={() => setShowGamesModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAppearanceModal} onHide={() => setShowAppearanceModal(false)}>
        <Modal.Header closeButton className="bg-gray-800 text-white">
          <Modal.Title>Manage Appearances</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray-700 text-white">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                value={newAppearance.date}
                onChange={(e) => setNewAppearance({...newAppearance, date: e.target.value})}
                className="bg-gray-600 text-white"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Time</Form.Label>
              <Form.Control
                type="time"
                value={newAppearance.time}
                onChange={(e) => setNewAppearance({...newAppearance, time: e.target.value})}
                className="bg-gray-600 text-white"
              />
            </Form.Group>
          </Form>
          <Button variant="success" onClick={handleAddAppearance} className="mt-3">
            Add Appearance
          </Button>
          <h4 className="mt-4">Current Appearances</h4>
          <ul className="list-unstyled">
            {prizeWheelAdmins.find(a => a._id === selectedAdminForAppearance)?.gameButtonAppearances.map((appearance, index) => (
              <li key={index} className="mb-2 d-flex justify-content-between align-items-center">
                {new Date(appearance.startTime).toLocaleString('en-GB', { hour12: false })}
                <Button 
                  variant="danger" 
                  size="sm" 
                  onClick={() => handleRemoveAppearance(selectedAdminForAppearance, index)}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer className="bg-gray-800">
          <Button variant="secondary" onClick={() => setShowAppearanceModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PowerBonusManager;