import React, { useState, useEffect } from 'react';
import Card from './Card';
import CryptoJS from 'crypto-js';

function AllCards() {
  const [cards, setCards] = useState([]);
  const API = process.env.REACT_APP_API;
  const API_V2 = 'https://phoenix-bingo-2.onrender.com/api';

  useEffect(() => {

    async function fetchCards() {
      try {
        // Retrieve the hashed API version from local storage
        const hashedApiVersion = localStorage.getItem('apiVersion');
        let apiVersion = '1'; // Default to version 1
        let apiEndpoint = API;

        // If a hashed version exists, check if it matches version 2
        if (hashedApiVersion) {
          const version2Hash = CryptoJS.SHA256('2').toString();
          if (hashedApiVersion === version2Hash) {
            apiVersion = '2';
            apiEndpoint = API_V2;
          }
        }

        // Use the appropriate endpoint based on the API version
        const endpoint = `${apiEndpoint}/card/getCards`;

        const response = await fetch(endpoint);
        const data = await response.json();
        
        // Sort the cards by palette number
        const sortedCards = data.sort((a, b) => a.paletteNumber - b.paletteNumber);
        setCards(sortedCards);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    }
    fetchCards();
  }, [API, API_V2]);

  return (
    <div>
      {cards.map(card => (
        <Card key={card.paletteNumber} card={card} />
      ))}
    </div>
  );
}

export default AllCards;