import React, { useEffect, useRef } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const particleAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -1000px, 0);
  }
`;

const cubeRotation = keyframes`
  0% {
    transform: rotateX(-15deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-15deg) rotateY(360deg);
  }
`;

const progressAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(102, 102, 102, 0.7) 0%, rgba(119, 119, 119, 0.7) 10%);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
`;

const ParticleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Particle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  animation: ${particleAnimation} 20s infinite linear;
`;

const LoadingCard = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 90%;
  backdrop-filter: blur(10px);
`;

const CubeContainer = styled.div`
  width: 100px;
  height: 100px;
  perspective: 1000px;
  margin-bottom: 30px;
`;

const Cube = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: ${cubeRotation} 10s infinite linear;
`;

const CubeFace = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #764ba2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #764ba2;
`;

const LoadingText = styled.div`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  margin-top: 20px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  height: 100%;
  background-color: #764ba2;
  animation: ${progressAnimation} 3s ease-in-out infinite;
`;

const ZolaLoading = () => {
  const particleRef = useRef(null);

  useEffect(() => {
    const container = particleRef.current;
    const particleCount = 50;

    for (let i = 0; i < particleCount; i++) {
      const particle = document.createElement('div');
      particle.style.left = `${Math.random() * 100}%`;
      particle.style.top = `${Math.random() * 100}%`;
      particle.style.animationDelay = `${Math.random() * 20}s`;
      container.appendChild(particle);
    }
  }, []);

  return (
    <OverlayContainer>
      <ParticleContainer ref={particleRef}>
        <Particle />
      </ParticleContainer>
      <LoadingCard>
        <CubeContainer>
          <Cube>
            <CubeFace style={{ transform: 'rotateY(0deg) translateZ(50px)' }}>Power</CubeFace>
            <CubeFace style={{ transform: 'rotateY(90deg) translateZ(50px)' }}>Keno</CubeFace>
            <CubeFace style={{ transform: 'rotateY(180deg) translateZ(50px)' }}>Spin</CubeFace>
            <CubeFace style={{ transform: 'rotateY(-90deg) translateZ(50px)' }}>Bingo</CubeFace>
            <CubeFace style={{ transform: 'rotateX(90deg) translateZ(50px)' }}>Games</CubeFace>
            <CubeFace style={{ transform: 'rotateX(-90deg) translateZ(50px)' }}>Racing</CubeFace>
          </Cube>
        </CubeContainer>
        <LoadingText>Preparing your amazing experience...</LoadingText>
        <ProgressBarContainer>
          <ProgressBar />
        </ProgressBarContainer>
      </LoadingCard>
    </OverlayContainer>
  );
};

export default ZolaLoading;