import { Container, Row, Col } from 'react-bootstrap';

function Card({ card }) {

  const isPower = window.location.href.toLowerCase().includes('power');

  return (
    <Container className={`mt-3 p-1 rounded fw-bold fs-3 ${isPower? 'bg-warning':'bg-white'}`} style={{maxWidth: '400px'}}>
      <h2 className='text-center'>{isPower ? 'Power Bingo' : 'Phoenix Bingo'}</h2>

      <Row className='mx-auto'>
        <Col xs={2} className="text-primary border p-0 text-center">
          <h3 className={`${isPower ? 'bg-warning':'bg-primary'} text-white mb-0`}>B</h3> 
          {card.numbers.B.map(num => ( 
            <div className={`${isPower? 'bg-warning': 'bg-white'} text-primary border`}>{num}</div> 
          ))}
        </Col>

        <Col xs={2} className="text-danger border p-0 text-center">
         <h3 className={`${isPower ? 'bg-warning':'bg-danger'} text-white mb-0`}>I</h3>
         {card.numbers.I.map(num => (
           <div className={`${isPower? 'bg-warning': 'bg-white'} text-primary border`}>{num}</div>
         ))}
        </Col>

        <Col xs={3} className="text-warning border p-0 text-center">
        <h3 className={`${isPower ? 'bg-warning':'bg-warning'} text-white mb-0`}>N</h3>
          {card.numbers.N.map((num,index) => (
            <div className={`${isPower? 'bg-warning': 'bg-white'} text-primary border`}>{num}</div>
          ))}
        </Col>

        <Col xs={2} className="text-success border p-0 text-center">
        <h3 className={`${isPower ? 'bg-warning':'bg-success'} text-white mb-0`}>G</h3>
          {card.numbers.G.map(num => (
            <div className={`${isPower? 'bg-warning': 'bg-white'} text-primary border`}>{num}</div>
          ))}
        </Col>

        <Col xs={2} className="text-info border p-0 text-center">
        <h3 className={`${isPower ? 'bg-warning':'bg-dark'} text-white mb-0`}>O</h3>
          {card.numbers.O.map(num => (
            <div className={`${isPower? 'bg-warning': 'bg-white'} text-primary border`}>{num}</div>
          ))}
        </Col>
      </Row>
      <h3 className='text-center fs-6 mt-2'>Card No. {card.paletteNumber}</h3>
    </Container>
  );

}

export default Card;